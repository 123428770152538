<template>
  <div class="klarna-container" ref="dom"></div>
</template>

<script type="text/javascript">
import { insertHTML } from '@/utils/index'
import order from '@/api/order'
export default {
  data() {
    return {
    }
  },
  mounted() {
    this.getPay()
  },
  methods: {
    getPay() {
      const { order_id } = this.$route.params
      const { klarna_order_id } = this.$route.query

      const cb = this.$toast.loading('加载中...')
      order.klarnaRetrieve(order_id, klarna_order_id)
      .then(res => {
        insertHTML(res.html_snippet, this.$refs.dom)
        .then(() => {
          window._klarnaCheckout(function (api) {
            api.on({
              "load": function () {
                // loading消失
                cb.clear()
              }
            })
          })
        })
        .catch(cb.clear)
      })
      .catch(() => {
        cb.clear()
      })
    }
  }
}
</script>

<style lang="less" scoped>
iframe {
  width: 100%;
  min-height: 100%;
}
</style>
